import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { configState } from "../Config";
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import AddCircle from "@material-ui/icons/AddCircle";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PublicIcon from "@material-ui/icons/Public";
import HomeIcon from "@material-ui/icons/Home";
import TheatersIcon from "@material-ui/icons/Theaters";
import ClockIcon from "@material-ui/icons/AccessTime";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ViewListIcon from "@material-ui/icons/ViewList";


import { Link } from "react-router-dom";

/*
 ** Test items are menu items only available in test settings such as staging and development
 */
var Test = false;
if (configState.siteState === "LIVE") {
  Test = true;
}

export const mainListItems = (
  <div>
    <ListItem component={Link} to="/" button disabled={false}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>

    <ListItem component={Link} to="/employees" button disabled={false}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Employees" />
    </ListItem>

    <ListItem component={Link} to="/houses" button disabled={false}>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Houses" />
    </ListItem>

    <ListItem component={Link} to="/chains" button disabled={false}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Chains" />
    </ListItem>

    <ListItem component={Link} to="/vendors" button disabled={false}>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Vendors" />
    </ListItem>

    <ListItem component={Link} to="/movies" button disabled={false}>
      <ListItemIcon>
        <TheatersIcon />
      </ListItemIcon>
      <ListItemText primary="MovieGuide" />
    </ListItem>
    <ListItem component={Link} to="/Codes" button>
      <ListItemIcon>
        <ViewListIcon />
      </ListItemIcon>
      <ListItemText primary="Codes" />
    </ListItem>
    <ListItem component={Link} to="/schedule" button disabled={false}>
      <ListItemIcon>
        <ClockIcon />
      </ListItemIcon>
      <ListItemText primary="Showtimes" />
    </ListItem>

    <ListItem component={Link} to="/automaters" button disabled={false}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Automaters" />
    </ListItem>

    <ListItem component={Link} to="/perfs" button disabled={false}>
      <ListItemIcon>
        <ConfirmationNumberIcon />
      </ListItemIcon>
      <ListItemText primary="Perfs" />
    </ListItem>

    

    <ListItem component={Link} to="/fastloader" button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="FastLoader" />
    </ListItem>


    <ListItem component={Link} to="/tiktok" button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="TikTok/PGLoad" />
    </ListItem>

    <ListItem component={Link} to="/api/users" button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="API User Management" />
    </ListItem>
  </div>
);

export const reportListItems = (
  <div>
    <ListSubheader>Showtime Reports</ListSubheader>

    <ListItem component={Link} to="/opening" button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Opening Info" />
    </ListItem>
    <ListItem component={Link} to="/bookinglinks" button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Booking Links" />
    </ListItem>
    <ListItem component={Link} to="/schedulestatus" button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Schedule Status" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader>MovieGuide Reports</ListSubheader>

    <ListItem component={Link} to="/foreign" button disabled={false}>
      <ListItemIcon>
        <PublicIcon />
      </ListItemIcon>
      <ListItemText primary="Foreign" />
    </ListItem>

    <ListItem component={Link} to="/mavenstats" button disabled={false}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Maven Stats" />
    </ListItem>

    <ListItem component={Link} to="/remaps" button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Remaps" />
    </ListItem>

    <ListItem component={Link} to="/movie-dupes" button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Movie Dupes" />
    </ListItem>

    <ListItem component={Link} to="/moviewidgets" button disabled={Test}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Movie Widgets" />
    </ListItem>

    <ListItem component={Link} to="/reports" button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Report" />
    </ListItem>
  </div>
);

export const tertiaryListItems = (
    <div>
        <ListSubheader>Import Tools</ListSubheader>

        <ListItem component={Link} to="/imports" button>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Imports" />
        </ListItem>

        <ListItem component={Link} to="/map-codes/list" button>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Map codes" />
        </ListItem>

        <ListItem component={Link} to="/stills" button>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Stills management" />
        </ListItem>

        <ListItem component={Link} to="/generic" button disabled={false}>
            <ListItemIcon>
                <ChromeReaderMode />
            </ListItemIcon>
            <ListItemText primary="GenericUI" />
        </ListItem>

        <ListItem component={Link} to="/fanweb" button>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Fandango Houses" />
        </ListItem>
    </div>
);
