import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddCircle from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import HouseDetail from "../HouseDetail";
import { createSortHandler } from "../TableSortHelperMethods";
import CsvConvert from "react-json-to-csv"
/*
 **
 ** WhereIsTable
 **
 */
export default class WhereIsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            screenSource: [],
            isLoading: false,
            queryUrl: this.props.queryUrl,
            selectedHouse: 0,
            detailOpen: false,
            houseOpen: false,
        };
    }

    componentDidUpdate(prevProps) {
        // if nothing important changed, we're done
        if (prevProps.queryUrl === this.props.queryUrl) return;

        this.setState({
            queryUrl: this.props.queryUrl, // search parameter
            isLoading: true,
        });

        // New props, have to rerun query
        this.executeQuery(this.props.queryUrl);
    }

    /*
     ** executeQuery - get data from API
     */
    async executeQuery(queryUrl) {
        console.log("Loading: " + queryUrl);
        console.log("Loading: " + queryUrl + "&screen=1");
        if (queryUrl === "") {
            this.setState({
                dataSource: [],
                isLoading: false,
            });
            return;
        }

        try {
            const response = await fetch(queryUrl);
            const responseJson = await response.json();
            const response1 = await fetch(queryUrl + "&screen=1");
            const responseJson1 = await response1.json();
            this.setState(
                {
                    isLoading: false,
                    dataSource: responseJson.rows == null ? [] : responseJson.rows,
                    screenSource: responseJson1.rows == null ? [] : responseJson1.rows,

                },
                function () {
                    // do something with new state
                }
            );
        } catch (error) {
            this.setState({
                isLoading: false,
            });
            console.error(error);
        }
    }
    handleClickOpen(houseId) {
        this.setState({
            selectedHouse: houseId,
            houseOpen: true,
        });
    }
    handleCloseHouseModal = () => {
        this.setState({
            houseOpen: false,
        });
    };

    // Return from modal
    handleCloseModal = () => {
        this.setState({
            detailOpen: false,
            selectedHouse: 0,
        });
    };
    /*
     ** render
     */
    render() {
        console.log("WhereIsTable: render");
        console.log("URL: " + this.state.queryUrl);
        if (this.state.isLoading) {
            return (
                <div style={{ flex: 1, paddingTop: 20 }}>
                    <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
                        <CircularProgress />
                    </span>
                </div>
            );
        } else if (this.state.dataSource.length === 0) {
            return <div style={{ flex: 1, paddingTop: 20 }}>{""}</div>;
        }
        //Variables used for with Showtimes report
        var PrevHouse = "";
        var PrevCircuit = "";
        var houseTable = [];
        var newChain = 0;
        var countHouse = 0;
        var tempHouse = []
        var divider = 0;
        var accordionCount = 0;
        /* House table */
        return (
            <Grid container spacing={4}>
                <HouseDetail
                    open={this.state.houseOpen}
                    house_id={this.state.selectedHouse}
                    onClose={this.handleCloseModal}
                    fullScreen={false}
                />
                {this.state.dataSource.map((row, index) => {
                    countHouse++;
                    //Ini House
                    if (PrevHouse === "") {
                        PrevHouse = row.housename;
                        PrevCircuit = row.chainname;
                        console.log("Initial House: " + PrevHouse);
                    }
                    //

                    //If a New Chain is Spotted, reset housetable
                    if (newChain === 1) {
                        newChain = 0;
                        houseTable = [];
                        houseTable.push(tempHouse[0]);
                        tempHouse = [];
                    }
                    var curCircuit;
                    //Push the house to the house table of the chain

                    //Set the Current House to the PrevHouse Variable
                    PrevHouse = row.housename;
                    if (PrevCircuit !== row.chainname) {
                        newChain = 1;
                        curCircuit = PrevCircuit;
                        PrevCircuit = row.chainname;
                        tempHouse.push(
                            <TableRow key={row.houseid}>
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            this.handleClickOpen(row.houseid);
                                        }}
                                        align="right"
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                    <IconButton
                                        href={"/schedule/" + row.chainid + "/" + row.houseid}
                                    >
                                        <CalendarTodayIcon />
                                    </IconButton>
                                    <IconButton
                                        href={"/schedule/" + row.chainid}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                </TableCell>
                                <TableCell title={row.moviename}>{row.moviename.substring(0, 20)}</TableCell>
                                <TableCell
                                    title={row.housename}
                                    style={{ fontWeight: "bold" }}
                                    align="left">
                                    {row.housename.substring(0, 20)}
                                </TableCell>
                                <TableCell>{row.houseid}</TableCell>
                                <TableCell title={row.altid} style={{ fontWeight: "bold" }}>
                                    {row.altid.substring(0, 20)}
                                </TableCell>
                                <TableCell>
                                    {row.screen}
                                </TableCell>
                                <TableCell>{row.city}</TableCell>
                                <TableCell>{row.state}</TableCell>
                                <TableCell>{row.country}</TableCell>
                            </TableRow>
                        )
                        divider = 1;
                        accordionCount++;
                        var movie = "movie" + accordionCount;
                        var house = "house" + accordionCount;
                        var house_id = "house_id" + accordionCount;
                        var altid = "alt_id" + accordionCount;
                        var city = "city" + accordionCount;
                        var screenCount = "screen" + accordionCount;
                        var state = "state" + accordionCount;
                        var countryCount = "country" + accordionCount;

                        return (
                            <Grid
                                item
                                xs={12}
                                xl={12}
                                sx={12}
                                sm={12}
                                key={index}
                                style={{ marginLeft: 30, marginRight: 30, padding: 10 }}
                            >
                                <Divider />
                                <React.Fragment>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography style={{ width: "33%", flexShrink: 0, color: "black", fontWeight: "bold" }}>
                                                Total House: {houseTable.length}
                                            </Typography>

                                            <Typography
                                                style={{ color: "black", fontWeight: "bold" }}
                                                href={"/schedule/" + row.chainid}
                                            >
                                                {curCircuit}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table style={{ whiteSpace: "nowrap" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { movie }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler({ movie }, this)}
                                                            >
                                                                Movie
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ width: "25%" }}
                                                            align="left"
                                                        >
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { house }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler({ house }, this)}

                                                            >
                                                                House
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { house_id }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler(
                                                                    { house_id },
                                                                    this
                                                                )}
                                                            >
                                                                House ID
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ width: "5%" }}
                                                            align="left">
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { altid }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler(
                                                                    { altid },
                                                                    this
                                                                )}
                                                            >
                                                                AltId
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell
                                                            align="left">
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { screenCount }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler(
                                                                    { screenCount },
                                                                    this
                                                                )}
                                                            >
                                                                Screen
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { city }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler({ city }, this)}
                                                            >
                                                                City
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { state }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler({ state }, this)}
                                                            >
                                                                State
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { countryCount }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler({ countryCount }, this)}
                                                            >
                                                                Country
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>{houseTable}</TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Divider />
                                </React.Fragment>
                            </Grid>
                        );
                    } else {
                        PrevCircuit = row.chainname;
                        houseTable.push(
                            <TableRow key={row.houseid}>
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            this.handleClickOpen(row.houseid);
                                        }}
                                        align="right"
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                    <IconButton
                                        href={"/schedule/" + row.chainid + "/" + row.houseid}
                                    >
                                        <CalendarTodayIcon />
                                    </IconButton>
                                    <IconButton
                                        href={"/schedule/" + row.chainid}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                </TableCell>
                                <TableCell title={row.moviename}>{row.moviename.substring(0, 20)}</TableCell>
                                <TableCell
                                    title={row.housename}
                                    style={{ fontWeight: "bold" }}
                                    align="left">
                                    {row.housename.substring(0, 20)}
                                </TableCell>
                                <TableCell>{row.houseid}</TableCell>
                                <TableCell title={row.altid} style={{ fontWeight: "bold" }}>
                                    {row.altid.substring(0, 20)}
                                </TableCell>
                                <TableCell>
                                    {row.screen}
                                </TableCell>
                                <TableCell>{row.city}</TableCell>
                                <TableCell>{row.state}</TableCell>
                                <TableCell>{row.country}</TableCell>
                            </TableRow>
                        );
                    }
                    if (countHouse === 1) {
                        return (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                style={{ marginLeft: 30, marginRight: 30, padding: 10 }}
                            >
                                <Box>

                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            textAlign: "right",
                                            marginTop: "25",
                                        }}
                                    >
                                        Total Houses: {this.state.dataSource.length}
                                        <br />
                                        Total Screen: {this.state.screenSource[0].totalscreen}
                                    </p>
                                </Box>
                                <CsvConvert
                                    data={this.state.dataSource}
                                    filename="report.csv"
                                    style={{
                                        borderRadius: "3px",
                                        border: "1px solid black",
                                        display: "inline-block",
                                        cursor: "pointer",
                                        fontSize: "12px",
                                        color: "black",
                                        fontWeight: "bold",
                                        padding: "6px 24px",
                                        textDecoration: "none",
                                    }}>
                                    Export to CSV
                                </CsvConvert>
                            </Grid>
                        )
                    }
                    if (divider === 1) {
                        divider = 0;
                        return (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                style={{ marginLeft: 30, marginRight: 30, padding: 10 }}
                            >
                                <Divider />
                            </Grid>
                        )
                    }
                    //Log the numbers

                    //When the last data is accessed, creates the final accordion
                    if (countHouse === this.state.dataSource.length) {

                        if (PrevCircuit === null) {
                            curCircuit = "Independent";
                        }
                        else {
                            curCircuit = row.chainname
                        }
                        return (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                style={{ marginLeft: 30, marginRight: 30, padding: 10 }}
                            >
                                <React.Fragment>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography style={{ width: "33%", flexShrink: 0, color: "black", fontWeight: "bold" }}>
                                                Total House: {houseTable.length}
                                            </Typography>

                                            <Typography
                                                style={{ color: "black", fontWeight: "bold" }}
                                            >
                                                {curCircuit}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table style={{ whiteSpace: "nowrap" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === "movie"}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler("movie", this)}
                                                            >
                                                                Movie
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === "house"}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler("house", this)}
                                                            >
                                                                House
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === "house_id"}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler(
                                                                    "house_id",
                                                                    this
                                                                )}
                                                            >
                                                                House ID
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === "altid"}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler(
                                                                    "altid",
                                                                    this
                                                                )}
                                                            >
                                                                AltId
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell
                                                            align="left">
                                                            <TableSortLabel
                                                                active={this.state.orderBy === { screenCount }}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler(
                                                                    { screenCount },
                                                                    this
                                                                )}
                                                            >
                                                                Screen
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === "city"}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler("city", this)}
                                                            >
                                                                City
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === "state"}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler("state", this)}
                                                            >
                                                                State
                                                            </TableSortLabel>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TableSortLabel
                                                                active={this.state.orderBy === "country"}
                                                                direction={this.state.order}
                                                                onClick={createSortHandler("country", this)}
                                                            >
                                                                Country
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>{houseTable}</TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                </React.Fragment>
                                <Divider />
                            </Grid>
                        );
                    }

                    return <span key={index} />;
                })}
            </Grid>
        );
    } // render
} // WhereIsTable.js
