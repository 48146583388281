import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../containers/Home";
import Dashboard from "../containers/Dashboard";
import Houses from "../containers/Houses";
import Vendors from "./Vendors";
import Chains from "../containers/Chains";
import Movies from "../containers/Movies";
import Mavenstats from "../containers/Mavenstats";
import Remaps from "../containers/Remaps";
import Perfs from "../containers/Perfs";
import Opening from "../containers/Opening";
import Automaters from "../containers/Automaters";
import Schedule from "../containers/Schedule";
import Foreign from "../containers/Foreign";
import Codes from "../containers/Codes";
import Reports from "../containers/Reports";
import ScheduleStatus from "../containers/ScheduleStatus";
import FastLoader from "../containers/FastLoader";
import NlConversions from "./NlConversions";
import BookingLinks from "../containers/BookingLinks";
import MovieWidgets from "../containers/MovieWidgets";
import TikTok from "../containers/TikTok";
import ApiUsers from "../components/API/Users";
import ApiCountries from "../components/API/Countries";
import ApiMovies from "../components/API/Movies";
import Generic from "../containers/Generic";
import CodesList from "../components/Codes/CodesList";
import CodesFiles from "../components/Codes/Files";
import CodesFileUpload from "../components/Codes/Upload";
import MovieDupes from "../components/MovieDupes";
import Imports from "../components/Imports";
import StillsManagement from "./StillsManagement";
import FanWeb from "./FanWeb";

export class Router extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.isOpen,
      setTitle: props.setTitle,
    };

    if (this.state.setTitle != null) this.state.setTitle("Routing...");
    else alert("Router: set title is null");
  }

  render() {
    return (
      <main>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Home {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/automaters"
            render={(props) => (
              <Automaters {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/chains"
            render={(props) => (
              <Chains {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            exact
            path="/employees"
            render={(props) => (
              <Dashboard {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            exact
            path="/fastloader"
            render={(props) => (
              <FastLoader {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            exact
            path="/codes"
            render={(props) => (
              <Codes {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/foreign"
            render={(props) => (
              <Foreign {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/houses/:house_id?"
            render={(props) => (
              <Houses {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/mavenstats"
            render={(props) => (
              <Mavenstats {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/movies"
            render={(props) => (
              <Movies {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/nlconversions"
            render={(props) => (
              <NlConversions {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/opening"
            render={(props) => (
              <Opening {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/perfs/:house_id?"
            render={(props) => (
              <Perfs {...props} setTitle={this.state.setTitle} />
            )}
          />

            <Route
                path="/generic"
                render={(props) => (
                    <Generic {...props} setTitle={this.state.setTitle} />
                )}
            />

          <Route
            path="/remaps"
            render={(props) => (
              <Remaps {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/movie-dupes"
            render={(props) => (
              <MovieDupes {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/schedule/:chain_id?/:house_id?"
            render={(props) => (
              <Schedule {...props} setTitle={this.state.setTitle} />
            )}
          />
          <Route
            path="/schedulestatus"
            render={(props) => (
              <ScheduleStatus {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/vendors"
            render={(props) => (
              <Vendors {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/moviewidgets"
            render={(props) => (
              <MovieWidgets {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/reports"
            render={(props) => (
              <Reports {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/tiktok"
            render={(props) => (
              <TikTok {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/bookinglinks"
            render={(props) => (
              <BookingLinks {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/api/users"
            render={(props) => (
              <ApiUsers {...props} setTitle={this.state.setTitle} />
            )}
          />
          <Route
            path="/api/countries/:idUser"
            render={(props) => (
              <ApiCountries {...props} setTitle={this.state.setTitle} />
            )}
          />
          <Route
            path="/api/movies/:idUser/:countryCode?"
            render={(props) => (
              <ApiMovies {...props} setTitle={this.state.setTitle} />
            )}
          />
          <Route
            path="/map-codes/list"
            render={(props) => (
              <CodesList {...props} setTitle={this.state.setTitle} />
            )}
          />
          <Route
            path="/map-codes/files"
            render={(props) => (
              <CodesFiles {...props} setTitle={this.state.setTitle} />
            )}
          />
          <Route
            path="/map-codes/upload"
            render={(props) => (
              <CodesFileUpload {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/imports"
            render={(props) => (
              <Imports {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/stills"
            render={(props) => (
              <StillsManagement {...props} setTitle={this.state.setTitle} />
            )}
          />

          <Route
            path="/fanweb"
            render={(props) => (
              <FanWeb {...props} setTitle={this.state.setTitle} />
            )}
          />
        </Switch>
      </main>
    );
  }
}
